/**
* Created by tudou on 2020/4/12 18:38.
*/
<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <div class="query__summary" slot="summary" v-has="'order:export'">
        <a-button class="mr-10" type="primary" icon="download" @click="orderExport">导出</a-button>
      </div>
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称" v-if="isStoreUser">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="订单号">
          <a-input v-model="query.orderId" placeholder="订单号"></a-input>
        </query-item>
        <query-item label="是否会员">
          <a-select allowClear v-model="query.isUser">
            <a-select-option :value="1">
              是
            </a-select-option>
            <a-select-option :value="2">
              否
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="会员卡号" v-show="query.isUser === 1">
          <a-input v-model="query.mobile" placeholder="会员卡号"></a-input>
        </query-item>
        <query-item label="订单状态">
          <a-select allowClear v-model="query.orderStatus">
            <a-select-option v-for="item in orderorderStatus" :key="item[0]" :value="item[0]">
              {{item[1]}}
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="支付方式">
          <a-select v-model="query.payMethod" placeholder="请选择">
            <a-select-option :value="item.value" v-for="item in payList" :key="item.value">
              {{item.label}}
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="下单时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>

    <!-- 创建订单弹出窗 开始 -->
    <create-order-modal v-model="orderVisible" @on-ok="handleToOrder" @on-cancel="handleCancelOrder"></create-order-modal>
    <!-- 创建订单弹出窗 结束 -->

    <!-- 收款弹出窗 开始 -->
    <payment-modal v-model="payVisible" :data="actionData" @on-ok="handlePayOk" @on-cancel="handleCancelPay" ></payment-modal>
    <!-- 收款弹出窗 结束 -->

    <!-- 取消订单 弹出窗 开始 -->
    <a-modal
      title="取消订单"
      :visible="cancelVisible"
      :confirm-loading="cancelLoading"
      @ok="handleDoCancel"
      @cancel="handleCloseCancel"
    >
      <p class="required mb-20">取消原因：</p>
      <a-input type="textarea" v-model="cancelReason" :auto-size="{ minRows: 4, maxRows: 6 }" placeholder="请输入取消原因"></a-input>
    </a-modal>
    <!-- 取消订单 弹出窗 结束 -->

    <!-- 退单弹出窗 开始 -->
    <a-modal
      title="退单"
      :visible="refundVisible"
      @ok="handleRefund"
      @cancel="refundVisible = false"
    >
      <p>确定要退单么？</p>
    </a-modal>
    <!-- 退单弹出窗 结束 -->

    <!-- 销售小票 开始 -->
    <order-bill ref="bill"></order-bill>
    <!-- 销售小票 结束 -->

    <!-- 自助机销售小票 开始 -->
    <diy-order-bill ref="diyBill"></diy-order-bill>
    <!-- 自助机销售小票 结束 -->
  </div>
</template>

<script>
import { orderList, orderCancel, orderRefund, orderDelete,orderExport } from '@/api/order';
import queryMixin from '@/mixin/queryMixin';
import CreateOrderModal from '@/components/CreateOrderModal.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import OrderBill from '@/components/print/OrderBill.vue';
import DiyOrderBill from '@/components/print/DiyOrderBill.vue';
import IDatePicker from '../../components/IDatePicker.vue';
import { isEmpty, deepClone } from '@/libs/utils';
import moment from "moment";

export default {
  name: 'OrderList',
  mixins: [queryMixin],
  components: {
    CreateOrderModal,
    PaymentModal,
    OrderBill,
    DiyOrderBill,
    IDatePicker
  },
  props: {},
  data() {
    return {
      query: {
        shopId: null,
        orderId: null,
        isUser: null,
        phone: null,
        orderStatus: null,
        duration: [],
        payMethod: ''
      },
      actionData: {},
      orderVisible: false, // 开单弹出层
      refundVisible: false, // 退单弹出层
      payVisible: false,
      cancelVisible: false,
      cancelLoading: false,
      cancelReason: '',
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          width: 200,
          ellipsis: true,
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          key: 'orderId',
          ellipsis: true,
          width: 250,
          customRender: (text, row) => {
            return (
              <div>
                <span class="mr-10">{row.orderId}</span>
                <a-popover title="会员订单" v-show={row.memberId}>
                  <template slot="content">
                    <p>姓名：{row.memberInfo && row.memberInfo.realName}</p>
                    <p>手机号：{row.memberInfo && row.memberInfo.mobile}</p>
                    <p>会员卡号：{row.memberInfo && row.memberInfo.memberCode}</p>
                  </template>
                  <a-tag size="small" ghost color="geekblue">Vip</a-tag>
                </a-popover>
              </div>
            );
          }
        },
        {
          title: '会员名称', // 会员显示名称 非会员显示散客
          dataIndex: 'memberName',
          key: 'memberName',
          width: 120,
          ellipsis: true,
        },
        {
          title: '订单来源',
          dataIndex: 'orderSourceName',
          key: 'orderSourceName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
          ellipsis: true,
          width: 120
        },
        {
          title: '商品数量',
          dataIndex: 'itemCount',
          key: 'itemCount',
          ellipsis: true,
          width: 120
        },
        {
          title: '订单金额',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true,
          width: 120
        },
        {
          title: '优惠金额',
          dataIndex: 'amountDiscount',
          key: 'amountDiscount',
          ellipsis: true,
          width: 120,
        },
        {
          title: '应付金额',
          dataIndex: 'amountPay',
          key: 'amountPay',
          width: 120,
        },
        {
          title: '实付金额',
          dataIndex: 'amountPaid',
          key: 'amountPaid',
          width: 120,
        },
        {
          title: '退款金额',
          dataIndex: 'amountRefund',
          key: 'amountRefund',
          width: 120,
        },
        {
          title: '支付方式',
          dataIndex: 'payMethodName',
          key: 'payMethodName',
          ellipsis: true,
          width: 160,
        },
        {
          title: '支付备注',
          dataIndex: 'discountWayRamarks',
          key: 'discountWayRamarks',
          ellipsis: true,
          width: 160,
        },
        {
          title: '下单时间',
          dataIndex: 'orderTime',
          key: 'orderTime',
          width: 180,
          ellipsis: true,
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatusName',
          key: 'orderStatusName',
          width: 120,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 260,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"  v-show={row.orderStatus > 1}></a-divider>
                <a-button type="link" size="small"  v-show={row.orderStatus > 1 && row.orderStatus !==9} onClick={ () => this.handlePrint(row) }>打印</a-button>
                <a-divider type="vertical" v-show={row.orderStatus === 1}></a-divider>
                <a-button type="link" size="small" v-show={row.orderStatus === 1} onClick={ () => this.handlePay(row) }>收款</a-button>
                <a-divider type="vertical" v-show={row.orderStatus < 3}></a-divider>
                <a-button type="link" size="small" v-show={row.orderStatus < 2 || row.orderStatus === 9} onClick={ () => this.handleDelete(row) }>删除订单</a-button>
                <a-divider type="vertical" v-show={row.orderStatus == 4}></a-divider>
                <a-button type="link" size="small" v-show={row.payTime > moment().subtract(0, 'days').format('YYYY-MM-DD 00:00:00') && row.orderStatus === 4 && row.timesCardType === 0} onClick={ () => this.handleShowRefund(row) }>退单</a-button>
              </section>
            );
          }
        }
      ],
      dataList: [],
      payList: [
        {
          label: '现金',
          value: 0
        },
        {
          label: '会员卡',
          value: 3
        },
        {
          label: '惠商-聚合支付',
          value: 6
        },
      ]
    };
  },
  computed: {
    orderorderStatus() {
      return this.$store.state.publicParams.orderStatus;
    },
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    }
  },
  watch: {},
  created() {
    this.handleQuery();
    // console.log(moment().subtract(0, 'days').format('YYYY-MM-DD 23:59:59'))
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      this.dataList = [];
      orderList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.orderId
        });
        this.dataList = list;
      });
    },

    // 添加
    handleAdd() {
      this.orderVisible = true;
    },

    // 点击开单按钮
    handleToOrder(data) {
      this.actionData = deepClone(data);
      this.orderVisible = false;
      this.payVisible = true;
    },

    // 取消开单
    handleCancelOrder() {
      this.orderVisible = false;
    },

    // 删除订单
    handleDelete(row) {
      const self = this;
      this.$confirm({
        title: '删除订单',
        content: '此操作将永远删除该订单，您确定要这么做吗？',
        okType: 'danger',
        onOk() {
          return new Promise((resolve, reject) => {
            const params = {
              orderId: row.orderId
            };
            orderDelete(params).then(() => {
              self.$message.success('删除成功');
              self.getData();
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    // 查看订单详情
    handleInfo(row) {
      this.$router.push({
        name: 'orderInfo',
        params: {
          id: row.orderId
        }
      });
    },

    // 打印小票
    handlePrint(row) {
      if (this.$store.getters.getStoreInfo.diySwitch === 1) {
        console.log('自助机订单打印');
        this.$nextTick(() => {
          this.$refs.diyBill.handlePrintBill(row.orderId);
        });
      } else {
        console.log('非自助机订单打印');
        this.$nextTick(() => {
          this.$refs.bill.handlePrintBill(row.orderId);
        });
      }
    },

    // 收款
    handlePay(row) {
      this.actionData = deepClone(row);
      this.payVisible = true;
    },

    // 取消原因弹窗
    handleCancel(row) {
      this.actionData = deepClone(row);
      this.cancelVisible = true;
    },

    // 退款弹出层
    handleShowRefund(row) {
      this.actionData = deepClone(row);
      this.refundVisible = true;
    },

    // 退款
    handleRefund() {
      const params = {
        orderId: this.actionData.orderId,
      };

      orderRefund(params).then(() => {
        this.refundVisible = false;
        this.getData(this.paramsId);
        this.$message.success('操作成功');
      });
    },

    // 取消订单
    handleDoCancel(row) {
      if (isEmpty(this.cancelReason)) {
        this.$message.error('请输入取消原因');
        return;
      }

      const params = {
        orderId: this.actionData.orderId,
        remarks: this.cancelReason
      };
      orderCancel(params).then(() => {
        this.$message.success('操作成功！');
        this.handleCloseCancel();
        this.getData();
      });
    },

    // 关闭取消订单
    handleCloseCancel() {
      this.actionData = {};
      this.cancelVisible = false;
      this.cancelReason = '';
    },

    // 支付成功
    handlePayOk() {
      this.getData();
    },
    // 取消付款
    handleCancelPay() {
      this.$message.warning('未完成支付，请到订单列表中重新付款');
      this.getData();
    },
    orderExport() {
      console.log("orderExport")
      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      orderExport(this.params).then((data) => {
        window.open(data);
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
